
import { SocialIcon } from 'react-social-icons';

function Navbar() {
  return (
    <div className="flex h-24 items-center justify-center bg-green-900 font-mono border-8 border-amber-900 w-max lg:w-screen">
      <div className='flex'>
        <p className="mx-auto text-center underline underline-offset-8 decoration-amber-900 decoration-double decoration-4 text-amber-50 font-bold tracking-wide text-3xl">The Works of C.K. Brandywine</p>
        <div className='mr-4 flex flex-col w-max ml-36'>
            <p className='text-amber-50 mb-2'>Follow me</p>
            <div className='flex'>
                <SocialIcon url='www.instagram.com' href='https://www.instagram.com/ckbrandywine' />
                <SocialIcon url='www.facebook.com' href='https://www.facebook.com/CKBrandywine' />
                <SocialIcon url='www.twitter.com' href='https://www.twitter.com/TheCKBrandywine' />
                <SocialIcon url='www.threads.net' href='https://www.threads.net/@ckbrandywine' />
            </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar;