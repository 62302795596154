import { useEffect } from "react";
import Navbar from "../components/Navbar";
import FaeriesBookmark from "../printComponents/FaeriesBookmark";

function Faeries() {

    useEffect(() => {
        document.title = 'Shadow Faeries - by C.K. Brandywine'
    }, []);

  return (
    <div className='flex flex-col px-4 pt-4 bg-green-900 items-center mx-auto'>
        <Navbar />
        <p className='text-center border-4 border-amber-900 rounded bg-amber-50 font-mono text-amber-900 font-bold text-5xl py-8 px-36 mb-4 mt-2'>Shadow Faeries Series<br /> <span className='text-3xl text-green-900'>by C.K. Brandywine</span> <br /></p>
        
        <div className='flex'>
            <div className='flex flex-col items-center bg-amber-50 p-4 border-4 border-amber-900 rounded w-1/4'>
                <FaeriesBookmark />
            </div>
            <div className='flex flex-col'>
                <div className='mx-2 border-amber-900 border-4 rounded bg-amber-50 h-fit p-2 mb-4'>
                    <p className="font-mono font-bold tracking-wide text-2xl text-amber-900 mb-4">Book One:  Shadow Faeries</p>
                    <p className='font-mono font-bold tracking-wide text-xl text-green-900 underline underline-offset-1'>Synopsis</p>
                    <p className='font-mono mt-4'>Maeve Lacewing is heir to the throne in Shadowland, destined to rule over the shadow faeries. But first she must go on her Luminescence, a trip all faeries take to the human world when they come of age. It's their chance to decide if they want to stay in the human world or return to Shadowland. For Maeve, this trip is symbolic. She must return to ascend the throne. But she's not sure if that's what she wants. She only knows that it's expected of her. If only she could live in both worlds.</p>
                    <p className='text-amber-900 font-bold mt-4'><span className="text-2xl">Click <a href="https://amzn.to/3wIN1we" target="_blank" rel="noreferrer" className="text-green-900 hover:text-4xl">here</a> to follow this story on Kindle Vella.</span></p>
                    <p className='text-amber-900 font-bold mt-4'><span className="text-2xl">Click <a href="https://amzn.to/3KkpEfG" target="_blank" rel="noreferrer" className="text-green-900 hover:text-4xl">here</a> to find the first book in the series on Kindle Unlimited or purchase in Kindle or paperback formats.</span></p>
                    <p className="text-2xl text-amber-900 font-bold mt-4">**Please note that the content in the novels and on Kindle Vella are the same**</p>
                </div>
                <div className='mx-2 border-amber-900 border-4 rounded bg-amber-50 h-fit p-2 mb-4'>
                    <p className="font-mono font-bold tracking-wide text-2xl text-amber-900 mb-4">Book Two:  Ascension</p>
                    <p className='font-mono font-bold tracking-wide text-xl text-green-900 underline underline-offset-1'>Synopsis</p>
                    <p className='font-mono mt-4'>Maeve Lacewing is growing up fast, though a small part of her wants to stay a kid. Her natural leadership continues to emerge, despite her bouts of immaturity, so much so that Daenica thinks she's ready to ascend the throne. Daenica sees all of Maeve's potential and is ready to hand over the reigns to her daughter, but Maeve thinks there is something more to Daenica's motivation to abdicate the throne so quickly. She knows her mother shouldn't be stepping down just yet, so why is she doing it?</p>
                    <p className='text-amber-900 font-bold mt-4'><span className="text-2xl">Click <a href="https://amzn.to/4ccGWr1" target="_blank" rel="noreferrer" className="text-green-900 hover:text-4xl">here</a> to follow this story on Kindle Vella.</span></p>
                    <p className="text-2xl text-amber-900 font-bold mt-4">Completed novel coming soon!</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Faeries