import { Link } from "react-router-dom";
import { SocialIcon } from "react-social-icons";

function Newsletter12() {
  return (
    <div className='p-8 bg-green-900 h-full font-mono text-xl'>
        <div className="w-4/5 mx-auto">
            <div className="flex items-center border-4 border-amber-900 bg-amber-50 p-4 mb-4">
                <div className="flex items-center">
                    <img src="../favicon.jpg" alt="The logo of C.K. Brandywine Publishing" className="w-1/5 md:w-1/12 rounded-full mr-4"></img>
                    <p className="text-amber-900 font-bold text-xl md:text-3xl">C.K. Brandywine Publishing Newsletter</p>
                </div>
                <div className='mr-4 flex flex-col whitespace-nowrap'>
                    <p className='text-amber-900 mb-2'>Follow me</p>
                    <div className='flex'>
                        <SocialIcon url='www.instagram.com' href='https://www.instagram.com/ckbrandywine' />
                        <SocialIcon url='www.facebook.com' href='https://www.facebook.com/CKBrandywine' />
                        <SocialIcon url='www.twitter.com' href='https://www.twitter.com/TheCKBrandywine' />
                        <SocialIcon url='www.threads.net' href='https://www.threads.net/@ckbrandywine' />
                    </div>
                </div>
            </div>
            <div className="flex flex-col border-4 border-amber-900 bg-amber-50 p-4">
                <p className="mb-5 text-2xl text-red-800 font-bold">Hello Happy Readers!</p>
                <p className="mb-4 leading-7">
                    Welcome to the twelfth edition of the newsletter for C.K. Brandywine Publishing.  I know it&apos;s been a while since I&apos;ve sent one of these out, but what can I say?  A lot of life has been happening lately.  But I hope you&apos;ll be happy to see that 
                    I&apos;ll be sending these out again and will try to keep them up with better regularity.  I&apos;m so pleased to report that I now have <span className="text-800 font-bold">NINE ACTIVE SERIES</span> on Kindle Vella and Kindle Unlimited as well as in Kindle and paperback formats.
                    Please see the links below for further information.  I also have plans for six more new series so please check back to my website or subscribe to this newsletter to see when those will be released.
                </p>
                <p className="mb-4 mt-4 leading-7">👉👉Please click <a href="https://www.amazon.com/s?k=c.k.+brandywine&rh=n%3A21165462011&ref=nb_sb_noss" target="_blank" rel="noreferrer" className="text-red-800 font-bold hover:text-green-900">here</a> to find me on Kindle Vella, if you haven&apos;t already.👈👈</p>
                <p className="mb-4 mt-4 leading-7">👉👉Please click <a href="https://www.amazon.com/s?k=C.+K.+Brandywine&i=digital-text&rh=n%3A154606011%2Cp_n_feature_nineteen_browse-bin%3A9045887011&dc&ds=v1%3AK7ljtQER6foPQ9ZPa9%2Bkj92kJyJ2u98EuZYO5NiB6Qc&crid=3OWO87FFGTRJ5&qid=1680125471&rnid=9045886011&sprefix=c.+k.+brandywine%2Cdigital-text%2C129&ref=sr_nr_p_n_feature_nineteen_browse-bin_1" target="_blank" rel="noreferrer" className="text-red-800 font-bold hover:text-green-900">here</a> to find my novels on Kindle Unlimited or available for purchase in Kindle and paperback formats.👈👈</p>
                <p className="mb-4 mt-4 leading-7">Also, please <Link to="/subscribe" target="_blank" rel="noreferrer" className="text-red-800 font-bold hover:text-green-900">💌subscribe💌</Link> to this newsletter so I can keep you up to date on all the latest things I&apos;m working on.</p>
                <p className="mb-4 mt-4 leading-7">
                    And don&apos;t forget to stop by my <a href="https://www.ckbrandywine.com/" target="_blank" rel="noreferrer" className="text-red-800 font-bold hover:text-green-900">website</a>.  You will find descriptions of all of my series there as well as a free printable bookmark for each.  There is also a link to a free online book journal for you to use as you wish.  I plan to continuously 
                    add more content and freebies to my website so please check back often.
                </p>
                <p className="mb-4 mt-4 leading-7">Please follow me on social media to keep up with all things Brandywine.  The links are up there 👆.</p>
                <p className=" mb-4 mt-4 text-2xl text-red-800 font-bold tracking-wide">What I&apos;m Writing</p>
                <p className="mb-4 mt-4 leading-7">I now have 24 stories available on Kindle Vella!!  Some are completed and some are in progress.  Each story that&apos;s in progress will have at least one new episode added each week, hopefully more.  Then, 
                as each story comes to a conclusion, I will collect the episodes and publish them as a completed novel.  Then the next story in the series will begin.  As of right now, I have fifteen completed novels available on Kindle Unlimited and in Kindle and paperback formats.  Please 
                visit my <a href="https://www.ckbrandywine.com/" target="_blank" rel="noreferrer" className="text-red-800 font-bold hover:text-green-900">website</a> for more information.</p>
                <p className="mb-4 mt-4 leading-7">I have included a brief description of each series below with links to my website for further information.  Please enjoy!</p>
                <p className="mb-2 mt-4 text-2xl text-red-800 font-bold tracking-wide"><a href="https://www.ckbrandywine.com/seasoned-witch" target="_blank" rel="noreferrer" className="text-red-800 font-bold hover:text-green-900">The Seasoned Witch Series</a></p>
                <p className=" mt-4 text-green-800 font-bold tracking-wide  underline underline-offset-1">Synopsis</p>
                <p className="mb-4 mt-4 leading-7">A young chef finds a treasure trove of witchy grimoires in her restaurant.  Chaos ensues.</p>
                
                <p className="mb-2 mt-12 text-2xl text-red-800 font-bold tracking-wide"><a href="https://www.ckbrandywine.com/haunting-of" target="_blank" rel="noreferrer" className="text-red-800 font-bold hover:text-green-900">The Haunting Of... Series</a></p>
                <p className=" mt-4 text-green-800 font-bold tracking-wide  underline underline-offset-1">Synopsis</p>
                <p className="mb-4 mt-4 leading-7">Haunted houses are everywhere.  Here are the stories of just a few.</p>                
                
                <p className="mb-2 mt-12 text-2xl text-red-800 font-bold tracking-wide"><a href="https://www.ckbrandywine.com/seer" target="_blank" rel="noreferrer" className="text-red-800 font-bold hover:text-green-900">The Seer Series</a></p>
                <p className=" mt-4 text-green-800 font-bold tracking-wide  underline underline-offset-1">Synopsis</p>
                <p className="mb-4 mt-4 leading-7">A young woman crashes her car and wakes up from a coma with the ability to see and communicate with the dead.  And now she must use her ability to help those who have tragically passed on.</p>
                
                <p className="mb-2 mt-12 text-2xl text-red-800 font-bold tracking-wide"><a href="https://www.ckbrandywine.com/spellbound" target="_blank" rel="noreferrer" className="text-red-800 font-bold hover:text-green-900">Spellbound: Tales of Myst Haven Series</a></p>
                <p className=" mt-4 text-green-800 font-bold tracking-wide  underline underline-offset-1">Synopsis</p>
                <p className="mb-4 mt-4 leading-7">A young woman buys some rare books for her bookstore from a mysterious stranger.  One of them transports her to another world.  A world that's in trouble.  Can she save their ancient magical race before they are overrun by dark forces?</p>                

                <p className="mb-2 mt-12 text-2xl text-red-800 font-bold tracking-wide"><a href="https://www.ckbrandywine.com/spirit" target="_blank" rel="noreferrer" className="text-red-800 font-bold hover:text-green-900">The S.P.I.R.I.T. Adventures Series</a></p>
                <p className=" mt-4 text-green-800 font-bold tracking-wide  underline underline-offset-1">Synopsis</p>
                <p className="mb-4 mt-4 leading-7">A motley crew of ghost hunters with a talent for seeing the other side use their powers to investigate hauntings and other disturbances in the hopes of proving once and for all that GHOSTS ARE REAL!</p>                

                <p className="mb-2 mt-12 text-2xl text-red-800 font-bold tracking-wide"><a href="https://www.ckbrandywine.com/lycan-saga" target="_blank" rel="noreferrer" className="text-red-800 font-bold hover:text-green-900">The Lycan Saga Series</a></p>
                <p className=" mt-4 text-green-800 font-bold tracking-wide  underline underline-offset-1">Synopsis</p>
                <p className="mb-4 mt-4 leading-7">A generations old pack of werewolves living on the southern end of the Appalachian trail tries to maintain its distance from the locals - the humans, that is - in an attempt to exist peacefully. But their worlds inevitably overlap, and it doesn't always go well.</p>
               
                <p className="mb-2 mt-12 text-2xl text-red-800 font-bold tracking-wide"><a href="https://www.ckbrandywine.com/wordsmith" target="_blank" rel="noreferrer" className="text-red-800 font-bold hover:text-green-900">The Wordsmith Series</a></p>
                <p className=" mt-4 text-green-800 font-bold tracking-wide  underline underline-offset-1">Synopsis</p>
                <p className="mb-4 mt-4 leading-7">Allie Periwinkle spends her days with her nose stuck in a book.  But then one day her mother gives her an antique journal and pen set that she found at a flea market.  Allie doesn't know that they're magic when she decides to write a story with them.  And then the characters emerge from the page.  Literally.</p>

                <p className="mb-2 mt-12 text-2xl text-red-800 font-bold tracking-wide"><a href="https://www.ckbrandywine.com/zombies-among-us" target="_blank" rel="noreferrer" className="text-red-800 font-bold hover:text-green-900">Zombies Among Us Series</a></p>
                <p className=" mt-4 text-green-800 font-bold tracking-wide  underline underline-offset-1">Synopsis</p>
                <p className="mb-4 mt-4 leading-7">Dr. Marianne Kobiak invented a drug that allowed people to live forever. But she realized too late that her miracle drug would turn half the world into zombies. She's now racing to find a cure and save the world. Meanwhile, everyone else is just trying to survive.</p>

                <p className="mb-2 mt-12 text-2xl text-red-800 font-bold tracking-wide"><a href="https://www.ckbrandywine.com/shadow-faeries" target="_blank" rel="noreferrer" className="text-red-800 font-bold hover:text-green-900">Shadow Faeries Series</a></p>
                <p className=" mt-4 text-green-800 font-bold tracking-wide  underline underline-offset-1">Synopsis</p>
                <p className="mb-4 mt-4 leading-7">Faeries are real and can live in the human world if they so choose.  Or they can choose to remain in Shadowland and live amongst their own kind.  Maeve Lacewing is the heir to the throne in Shadowland so, for her, there is no choice.  Or is there?</p>

                <p className=" mb-4 mt-12 text-2xl text-red-800 font-bold tracking-wide">What You&apos;re Writing</p>
                <p className="mb-4 mt-4 leading-7">And now it&apos;s your turn to write.  Here&apos;s another prompt to start your creative wheel turning.  I&apos;ll be posting this on social media so be sure to reach out and let me know what you come up with.</p>
                <div className="border-2 border-dashed bg-gray-50 p-4">
                    <p className="mb-4 mt-4 leading-7">The mountainside glowed red under the blazing hot sun. But night would fall soon. And with nightfall came the eyes that would be watching from the darkness...</p>
                    <br />
                    <p className="mb-4 mt-4 leading-7">Your story continues from here…</p>
                </div>
                <p className="mb-4 mt-4 leading-7">And that&apos;s it!  Thank you for reading the twelfth edition of my newsletter.  I hope to hear from you soon!</p>
                <p className="mt-4 leading-7">Creativity can help you reach inside your soul and find something great,</p>
                <p>C.K.</p>
                <p>June 1, 2024</p>
            </div>
        </div>
    </div>
  )
}

export default Newsletter12;